import { component, observable, subscribe } from 'knockout-decorators'

import { pageState } from '~/store/page'

import './image-changer.less'
import source from './image-changer.html?raw'

@component('image-changer', source)
export class ImageChanger {
  @observable public image = ''
  @observable public element: HTMLImageElement | null = null

  constructor(params: { image: string }) {
    subscribe(
      () => pageState[params.image],
      (newImage) => {
        this.fadeToImage(newImage)
      }
    )
  }

  public fadeToImage(src: string) {
    this.image = src

    const newImage = new Image()
    newImage.addEventListener('load', this.nextImageReady)
    newImage.src = src
  }

  private initImage(image: HTMLImageElement) {
    this.element = image
  }

  private nextImageReady = (event: Event) => {
    if (this.element && this.element.parentNode) {
      this.element.classList.add('off')
      this.element.insertAdjacentElement(
        'afterend',
        event.target as HTMLImageElement
      )
      this.removeOldImage(this.element)
      this.element = event.target as HTMLImageElement
    }
  }

  private removeOldImage(image: HTMLImageElement) {
    setTimeout(() => {
      image.removeEventListener('load', this.nextImageReady)
      if (image.parentNode) {
        image.parentNode.removeChild(image)
      }
    }, 1000)
  }
}
